import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./additional-funding.css"
import StateFederal from "../data/af-statefederal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby"

const AdditionalFundingStateAndFederal = () => {
    const [statefederal, setStateFederal] = useState(StateFederal.stateFederal)
  return (
    <Layout>
      <SEO title="State And Federal Loans" />
      <div>
        <div className="container primary-bg">
        <div>
          <Link className="additonalFundingHeader"  to="/additional-funding">Available Funding Sources</Link>
          </div>
        <div>
        <label className="additonalFundingTitle">State and Federal Loans</label>
        </div>
          <hr />
          <section className="container">
            {statefederal
              .sort((a, b) => (a.FoundationName > b.FoundationName ? 1 : -1))
              .map((statefederal, index) => {
                return (
                  <div className="card">
                      <label className="additonalFundingCardTitle">{statefederal.FoundationName}</label>
                      <div>
                        <FontAwesomeIcon
                          className="iconStyle"
                          size="lg"
                          icon={faGlobe}
                        />
                        <a
                          className="additonalFunding"
                          href={statefederal.link}
                          target="_blank"
                        >
                          {statefederal.link}
                        </a>
                      </div>
                  </div>
                )
              })}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AdditionalFundingStateAndFederal
