export const AFStateFederal = {
  stateFederal: [
    {
      "FoundationName": "Florida Bridge Loans (FDEO)",
      "link": "http://floridajobs.org/rebuildflorida/businessrecovery",
    },
    {
      "FoundationName": "SBA Emergency Disaster Injury Loans",
      "link": "https://covid19relief.sba.gov/#/",
    },
    {
      "FoundationName": "The CARES Act Provides Assistance to Small Businesses",
      "link": "https://home.treasury.gov/policy-issues/cares/assistance-for-small-businesses",
    },
    {
      "FoundationName": "Paycheck Protection Program (PPP)",
      "link": "https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program",
    },
    {
      "FoundationName": "Paycheck Protection Program (PPP) Guidance on Accessing Capital for Minority, Underserved, Veteran and Women-Owned Business Concerns",
      "link": "https://www.sba.gov/sites/default/files/2021-01/Guidance%20on%20Accessing%20Capital%20for%20Minority%20Underserved%20Veteran%20and%20Women%20Owned%20Business%20Concerns%20.pdf",
    },
  ],
}

export default AFStateFederal
